<template>
  <div class="info-active-imei-config">
    <KTCodePreview v-bind:title="title">
      <template v-slot:preview>
        <b-card no-body class="full-width">
          <div>
            <div
              class="wizard wizard-4"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav-->
              <div class="wizard-nav" style="background-color: #eef0f8">
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Thông tin</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wizard-step ml-1"
                    data-wizard-type="step"
                    v-show="mainModel.id"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Lịch sử kích hoạt</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom card-shadowless rounded-top-0">
                <div class="card-body p-0">
                  <div class="row">
                    <div class="col-xl-12">
                      <div
                        class="py-8 px-4 py-lg-15 px-lg-4 pb-5 full-width"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                      >
                        <b-row>
                          <b-col cols="6">
                            <b-form-group class="required-control">
                              <label>Tên chương trình kích hoạt:</label>
                              <b-form-input
                                size="sm"
                                v-model="mainModel.name"
                                placeholder="Nhập tên"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col cols="3">
                            <b-form-group class="required-control">
                              <label>Thời gian:</label>
                              <div
                                class="d-flex justify-content-center align-items-center"
                              >
                                <date-picker
                                  placeholder="Từ ngày"
                                  class="form-control-sm"
                                  :config="dpConfigs.date"
                                  v-model="mainModel.startDate"
                                ></date-picker>
                                <span class="ml-1 mr"></span>
                                <date-picker
                                  placeholder="Đến ngày"
                                  class="form-control-sm"
                                  :config="dpConfigs.date"
                                  v-model="mainModel.endDate"
                                ></date-picker>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col cols="3">
                            <b-form-group>
                              <label>Trạng thái:</label>
                              <b-form-select
                                size="sm"
                                class="select-style"
                                v-model="mainModel.isActive"
                                :options="[
                                  {
                                    id: 1,
                                    name: 'Hoạt động',
                                  },
                                  {
                                    id: 0,
                                    name: 'Ngừng hoạt động',
                                  },
                                ]"
                                value-field="id"
                                text-field="name"
                              ></b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <hr
                          class="hr-text"
                          data-content="Chỉ tiêu kích hoạt"
                          style="font-weight: 600"
                        />
                        <b-row class="mb-4">
                          <b-col md="6">
                            <treeselect
                              :options="stores"
                              v-model="selectedStores"
                              :multiple="true"
                              :normalizer="normalizer"
                              :backspace-removes="false"
                              placeholder="Chọn cửa hàng"
                              :max-height="200"
                              noResultsText="Không tìm thấy kết quả"
                              valueFormat="object"
                              :clearable="false"
                            ></treeselect>
                          </b-col>
                          <b-col md="1">
                            <b-button
                              style="width: 40px"
                              variant="secondary"
                              size="sm"
                              v-b-tooltip.hover
                              title="Thêm cửa hàng"
                              @click="onAddStore()"
                            >
                              <i class="fas fa-arrow-down"></i>
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="6">
                            <b-table
                              bordered
                              hover
                              :fields="targetFields"
                              :items="mainModel.targetItems"
                            >
                              <template v-slot:cell(quantityTarget)="row">
                                <b-form-input
                                  size="sm"
                                  v-model="row.item.quantityTarget"
                                  placeholder="Nhập số lượng"
                                  class="text-right"
                                ></b-form-input>
                              </template>
                              <template v-slot:cell(actions)="row">
                                <v-icon
                                  small
                                  class="text-danger"
                                  @click="showDeleteAlert(row.item.id, 'store')"
                                  v-b-tooltip
                                  title="Xóa"
                                  >mdi-delete</v-icon
                                >
                              </template>
                              <template slot="bottom-row">
                                <td
                                  v-bind:colspan="3"
                                  class="text-right font-weight-bolder"
                                  style="font-weight: 600,color: '#181c32'"
                                >
                                  <span>Tổng</span>
                                </td>
                                <td class="text-right font-weight-bolder">
                                  <span>{{
                                    convertPrice(sumQuantityTarget)
                                  }}</span>
                                </td>
                                <td class="text-right font-weight-bolder">
                                  <span>{{
                                    convertPrice(sumQuantityActived)
                                  }}</span>
                                </td>
                              </template>
                            </b-table>
                          </b-col>
                        </b-row>
                        <div class="my-5"></div>
                        <hr
                          class="hr-text"
                          data-content="Cấu hình cho sản phẩm"
                          style="font-weight: 600"
                        />
                        <b-row>
                          <b-form-group class="col-md-12 pr-0">
                            <label>Tìm sản phẩm:</label>
                            <b-col class="row pr-0">
                              <b-col md="6" class="p-0">
                                <Autosuggest
                                  :model="searchProductFor"
                                  :suggestions="optionProducts"
                                  placeholder="tên, mã, mã vạch sản phẩm"
                                  :limit="10"
                                  @select="onSelectedProductFor"
                                  @change="onInputChangeProductFor"
                                  :disabled="false"
                                  suggestionName="productName"
                                />
                              </b-col>
                              <b-col md="2" class="p-0">
                                <b-form-select
                                  class="select-style border-left-0 rounded-left-0 rounded-right-0"
                                  v-model="selectTypeSearchFor"
                                  :options="[
                                    {
                                      id: 1,
                                      name: 'Sản phẩm IMEI',
                                    },
                                  ]"
                                  size="sm"
                                  value-field="id"
                                  text-field="name"
                                  disabled-field="notEnabled"
                                  @change="debounceInputFor"
                                ></b-form-select>
                              </b-col>
                              <b-col md="2" class="p-0">
                                <b-form-select
                                  class="select-style border-left-0 rounded-left-0"
                                  v-model="selectTypeOfProductFor"
                                  :options="productTypes"
                                  size="sm"
                                  value-field="id"
                                  text-field="name"
                                  disabled-field="notEnabled"
                                  @change="debounceInputFor"
                                ></b-form-select>
                              </b-col>
                            </b-col>
                          </b-form-group>
                        </b-row>
                        <b-row>
                          <b-col md="10">
                            <div>
                              <b-table
                                bordered
                                hover
                                class="col-md-12"
                                :fields="productfields"
                                :items="mainModel.appliedProducts"
                                :per-page="10"
                                :current-page="currentPageProductTable"
                              >
                                <template v-slot:cell(actions)="row">
                                  <v-icon
                                    small
                                    class="text-danger"
                                    @click="
                                      showDeleteAlert(row.item.id, 'productFor')
                                    "
                                    v-b-tooltip
                                    title="Xóa"
                                    >mdi-delete</v-icon
                                  >
                                </template>
                              </b-table>
                              <b-pagination
                                v-show="mainModel.appliedProducts.length > 10"
                                v-model="currentPageProductTable"
                                :total-rows="mainModel.appliedProducts.length"
                                :per-page="10"
                                align="right"
                              ></b-pagination>
                            </div>
                          </b-col>
                          <b-col cols="6" md="4"></b-col>
                        </b-row>
                      </div>
                      <div
                        class="col-xl-12 pb-5 full-width"
                        data-wizard-type="step-content"
                      >
                        <div class="col-8 mt-4">
                          <b-table
                            bordered
                            hover
                            :fields="activeLogFields"
                            :items="activeLogs"
                            :per-page="30"
                            :current-page="currentPageLog"
                          >
                            <template v-slot:cell(statusResponseName)="row">
                              <span
                                style="width: max-content"
                                v-text="row.item.statusResponseName"
                                v-bind:class="
                                  bindClassNameStatus(row.item.statusResponse)
                                "
                              ></span>
                            </template>
                          </b-table>
                          <b-pagination
                            v-show="activeLogs.length > 30"
                            v-model="currentPageLog"
                            :total-rows="activeLogs.length"
                            :per-page="30"
                            align="right"
                          ></b-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </template>
      <template v-slot:foot>
        <b-button
          v-bind:style="btnCreate"
          size="sm"
          variant="primary"
          class="font-weight-bolder mr-4"
          @click="onSubmit"
        >
          Lưu
        </b-button>
        <b-button
          v-bind:style="btnCreate"
          size="sm"
          variant="secondary"
          class="font-weight-bolder"
          @click="onBack"
        >
          Hủy
        </b-button>
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.input-style {
  border: 1px solid #ced4da;
}
input.form-control {
  border: 1px solid #ced4da;
}
.muti-basic-custom .rounded {
  border-radius: 0.28rem !important;
}
.multi-select-wrapper {
  flex: 1 1 auto;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { getToastInstance } from '@/utils/toastHelper';
import { cloneDeep, sumBy, reject } from 'lodash';
import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import {
  currencyMask,
  makeToastFaile,
  makeToastSuccess,
  convertPrice,
} from '@/utils/common';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import { v4 } from 'uuid';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { DP_CONFIG } from '@/utils/date';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import { SAMSUNG_ACTIVE_STATUS } from '@/utils/enum';

export default {
  mixins: [validationMixin],
  data() {
    return {
      title: '',
      dpConfigs: DP_CONFIG,
      selectedStores: [],
      targetFields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên',
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' },
          tdClass: 'text-left',
        },
        {
          key: 'siteCode',
          label: 'siteCode',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-left',
        },
        {
          key: 'quantityTarget',
          label: 'Chỉ tiêu',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-right',
        },
        {
          key: 'quantityActived',
          label: 'Đã kích',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-right',
        },
        { key: 'actions', label: '', tdClass: 'text-center' },
      ],
      mainModel: {
        id: null,
        name: '',
        startDate: new Date(),
        endDate: null,
        targetItems: [],
        appliedCategories: [],
        appliedProducts: [],
        isActive: 1,
      },
      productfields: [
        {
          key: 'barCode',
          label: 'Mã vạch',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
          },
          tdClass: 'text-left',
        },
        {
          key: 'productCode',
          label: 'Mã',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
          },
          tdClass: 'text-left',
        },
        {
          key: 'productName',
          label: 'Tên',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
          },
          tdClass: 'text-left',
        },
        { key: 'actions', label: '', tdClass: 'text-center' },
      ],
      filteredProducts: [],
      searchProductFor: '',
      categories: [],
      isSearching: false,
      productTypes: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeSearchFor: 1,
      selectTypeOfProductFor: 0,
      currentPageProductTable: 1,
      mask: currencyMask,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
      isBusy: false,
      stores: [],
      searchProduct: '',
      filteredItems: [],
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
        width: '70px',
      },
      selectTypeSearch: 2,
      selectTypeOfProduct: 0,
      activeLogFields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        {
          key: 'imeiCode',
          label: 'IMEI',
          thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
          tdClass: 'text-left',
        },
        {
          key: 'referenceId',
          label: 'Hóa đơn',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-left',
        },
        {
          key: 'statusResponseName',
          label: 'Trạng thái',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-center',
        },
        {
          key: 'createdAt',
          label: 'Thời gian',
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
          tdClass: 'text-left',
        },
      ],
      activeLogs: [],
      currentPageLog: 1,
    };
  },
  components: {
    KTCodePreview,
    datePicker,
    Treeselect,
    Autosuggest,
  },
  watch: {},
  computed: {
    optionProducts() {
      return [...cloneDeep(this.filteredProducts)];
    },
    sumQuantityTarget() {
      return this.mainModel.targetItems
        ? sumBy(this.mainModel.targetItems, (targetItem) =>
            Number(targetItem.quantityTarget),
          )
        : 0;
    },
    sumQuantityActived() {
      return this.mainModel.targetItems
        ? sumBy(this.mainModel.targetItems, (targetItem) =>
            Number(targetItem.quantityActived),
          )
        : 0;
    },
  },
  mounted() {
    this.title = 'Thêm mới cấu hình kích hoạt';
    if (this.$route.query.id) {
      this.title = 'Cập nhật cấu hình kích hoạt';
      this.mainModel.id = this.$route.query.id;
      this.getInfoById();
      this.getLogById();
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
    // Initialize form wizard
    const hashName = this.$route.hash ? this.$route.hash.split('#')[1] : '';

    this.hashName = hashName === 'attach' ? 'attach' : null;

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: hashName === 'attach' ? 2 : 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  async created() {
    this.getCategories();
    this.stores = await this.fetchStore();
  },
  methods: {
    ...getToastInstance(),
    convertPrice,
    getCategories() {
      ApiService.query('category')
        .then(({ data }) => {
          if (!data.status) {
            return makeToastFaile('Lấy dữ liệu danh mục thất bại!');
          }
          this.categories = data.data.list_caterogy;
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    showDeleteAlert: function (id, type) {
      Swal.fire({
        title: 'Xóa dữ liệu!',
        text: `Bạn có chắc muốn xóa dữ liệu này không ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then(async (result) => {
        if (result.dismiss != 'cancel') {
          if (type === 'store') {
            this.mainModel.targetItems = reject(
              this.mainModel.targetItems,
              (item) => {
                return item.id == id;
              },
            );
          } else if (type === 'productFor') {
            this.mainModel.appliedProducts = reject(
              this.mainModel.appliedProducts,
              (item) => {
                return item.id == id;
              },
            );
          }
        }
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.filteredProducts = [];
      const params = {
        searchProduct: textSearch ? textSearch.trim() : null,
        typeSearch: this.selectTypeSearchFor,
        typeOfProduct: this.selectTypeOfProductFor,
      };
      ApiService.query(`productSearch/search-by-type`, {
        params,
      }).then((response) => {
        this.filteredProducts = response.data.data;
        this.isSearching = false;
      });
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';
      const index = this.mainModel.appliedProducts.findIndex(
        (product) => product.productId === option.item.productId,
      );
      if (index > -1) {
        return makeToastFaile(`${option.item.productName} đã tồn tại`);
      }
      this.mainModel.appliedProducts.push({
        id: v4(),
        productId: option.item.productId,
        barCode: option.item.barCode,
        productName: option.item.productName,
        productCode: option.item.productCode,
      });
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;
      this.debounceInputFor();
    },
    onBack: function () {
      history.back();
    },
    initPayload() {
      const params = cloneDeep(this.mainModel);
      const items = this.mainModel.targetItems.map((item) => {
        return {
          id: item.id,
          storeId: item.storeId,
          quantityTarget: item.quantityTarget,
        };
      });

      return {
        id: params.id,
        name: params.name,
        isActive: params.isActive,
        startDate: params.startDate
          ? moment(params.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        endDate: params.endDate
          ? moment(params.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        items: items,
        products: this.mainModel.appliedProducts,
        categories: this.mainModel.appliedCategories,
      };
    },
    onSubmit: async function () {
      this.isBusy = true;

      if (!this.mainModel.name || !this.mainModel.name.trim()) {
        makeToastFaile('Vui lòng nhập tên cấu hình kích hoạt!');
        return;
      }

      if (!this.mainModel.startDate || !this.mainModel.endDate) {
        makeToastFaile('Vui lòng nhập thời gian cho cấu hình kích hoạt!');
        return;
      }

      if (!this.mainModel.targetItems || !this.mainModel.targetItems.length) {
        makeToastFaile('Vui lòng nhập chỉ tiêu kích hoạt!');
        return;
      }

      const missSiteCodes = [];
      const missTargets = [];
      for (const targetItem of this.mainModel.targetItems) {
        if (!targetItem.siteCode) {
          missSiteCodes.push(targetItem.name);
        }
        if (!targetItem.quantityTarget || targetItem.quantityTarget <= 0) {
          missTargets.push(targetItem.name);
        }
      }
      if (missSiteCodes.length) {
        const storeNames = missSiteCodes.join(', ');
        makeToastFaile(`Vui lòng nhập siteCode cho cửa hàng: ${storeNames}`);
        return;
      }
      if (missTargets.length) {
        const storeNames = missTargets.join(', ');
        makeToastFaile(`Vui lòng nhập chỉ tiêu cho cửa hàng: ${storeNames}`);
        return;
      }

      const payload = this.initPayload();
      if (!payload.id) {
        this.onCreate(payload);
      } else {
        this.onUpdate(payload);
      }
    },
    onCreate(payload) {
      ApiService.post('v2/active-configs', payload)
        .then((response) => {
          this.isBusy = false;
          const { status, message } = response.data;
          if (status === 200) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
        });
    },
    onUpdate(payload) {
      ApiService.put('v2/active-configs', payload)
        .then((response) => {
          this.isBusy = false;
          const { status, message } = response.data;
          if (status === 200) {
            makeToastSuccess(message);
            history.back();
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          this.isBusy = false;
          const message = err.response.data.message;
          makeToastFaile(message);
        });
    },
    debounceInputFor: decounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProductFor;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    getInfoById() {
      ApiService.get(`v2/active-configs/${this.mainModel.id}`)
        .then(({ data }) => {
          this.mainModel.isActive = data.data.isActive;
          this.mainModel.name = data.data.name ? data.data.name : '';
          this.mainModel.startDate = data.data.startDate
            ? moment(data.data.startDate).format('DD-MM-YYYY')
            : '';
          this.mainModel.endDate = data.data.endDate
            ? moment(data.data.endDate).format('DD-MM-YYYY')
            : '';
          this.mainModel.targetItems = data.data.items.map((item, index) => {
            return {
              ...item,
              count: index + 1,
            };
          });
          this.mainModel.appliedCategories = data.data.appliedCategories;
          this.mainModel.appliedProducts = data.data.appliedProducts;
        })
        .catch((response) => {
          console.log('response: ', response);
          makeToastFaile(response.$error);
        });
    },
    fetchStore: function () {
      return ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        return stores.map((store) => {
          return {
            id: store.id,
            name: store.name,
            shortName: store.shortName,
            siteCode: store.siteCode ? store.siteCode : '',
          };
        });
      });
    },
    onAddStore() {
      if (!this.selectedStores || !this.selectedStores.length) {
        return makeToastFaile('Vui lòng chọn cửa hàng muốn thêm!');
      }
      const selectedStoreIds = this.selectedStores.map(
        (selectedStore) => selectedStore.id,
      );
      const existedSelectedStores = this.mainModel.targetItems.filter(
        (targetItem) => selectedStoreIds.includes(targetItem.storeId),
      );
      if (existedSelectedStores && existedSelectedStores.length) {
        const storeNames = existedSelectedStores
          .map((existedSelectedStore) => existedSelectedStore.name)
          .join(', ');
        return makeToastFaile(
          `Cửa hàng ${storeNames} đã tồn tại trong danh sách chỉ tiêu!`,
        );
      }
      const stores = this.selectedStores.map((store) => {
        return {
          ...store,
          quantityTarget: 0,
          quantityActive: 0,
          id: v4(),
          storeId: store.id,
        };
      });

      this.mainModel.targetItems = stores.concat(this.mainModel.targetItems);
      this.mainModel.targetItems.map((item, index) => {
        item.count = index + 1;
      });
      this.selectedStores = [];
    },
    getLogById() {
      ApiService.get(`v2/active-configs/log-by-config/${this.mainModel.id}`)
        .then(({ data }) => {
          this.activeLogs = data.data.map((dataResponse, index) => {
            return {
              ...dataResponse,
              count: index + 1,
            };
          });
        })
        .catch((response) => {
          console.log('response: ', response);
          makeToastFaile(response.$error);
        });
    },
    bindClassNameStatus(statusResponse) {
      console.log('statusResponse: ', statusResponse);
      let className = '';
      switch (statusResponse) {
        case SAMSUNG_ACTIVE_STATUS.CHK_FMT_I2: {
          className =
            'label font-weight-bold label-lg label-inline label-light-warning';
          break;
        }
        case SAMSUNG_ACTIVE_STATUS.IMEI_ACTV: {
          className = 'badge badge-success';
          break;
        }
        case SAMSUNG_ACTIVE_STATUS.IMEI_NO: {
          className = 'badge badge-dark';
          break;
        }
        case SAMSUNG_ACTIVE_STATUS.IMEI_DUPL: {
          className = 'badge badge-secondary';
          break;
        }
        case SAMSUNG_ACTIVE_STATUS.IMEI_NOMOD: {
          className = 'badge badge-warning';
          break;
        }
        case SAMSUNG_ACTIVE_STATUS.IMEI_OLDMD: {
          className = 'badge badge-info';
          break;
        }
        case SAMSUNG_ACTIVE_STATUS.NOAUTH2: {
          className = 'badge badge-danger text-dark';
          break;
        }
        case SAMSUNG_ACTIVE_STATUS.IMEI_WRGFM: {
          className = 'badge badge-danger text-dark';
          break;
        }
      }
      return className;
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
